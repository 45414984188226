import muiTheme from './muiTheme';

const theme = {
  ...muiTheme,
  app: {
    maxWidth: '100%',
  },
};

export default theme;
